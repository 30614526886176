import { action, computed, observable, runInAction } from 'mobx';
import AbstractStore from './AbstractStore';
import { isDefined, sortByField } from '../utils/object-utils';
import type { ModalProps } from '../components/Modal/Modal';
import type { NavigateFunction } from 'react-router';
import type { Sap } from '../types/users-types';
import { myCsUrl } from '../config/mycs';
import { PracticeOffersResponse, PracticeResponse } from '../types/practices-types';
import { storageService } from '../services/storage.service';
import { DropDownOption, PracticeSpeciesBreeds, VendorSpeciesBreeds } from '../types/species-breeds-types';
import React from 'react';
import { MessageLeavePage } from './messages';

const MODAL_ID = 'global-modal';

interface StepsCompleted {
  practices?: boolean;
  offers?: boolean;
  species?: boolean;
}

export default class GlobalStore extends AbstractStore {
  @observable private _selectedSap: Sap = { id: '', name: '' };

  @observable _hasUnsavedChanges = false;

  @computed
  get selectedSap(): Sap {
    if (!this._selectedSap.id) {
      const sap = storageService.getItem('SAP', false);
      if (sap) this._selectedSap = sap;
    }
    return this._selectedSap;
  }

  set selectedSap(value: Sap | null) {
    if (value) {
      storageService.update('SAP', value, false);
      this._selectedSap = value;
    } else {
      storageService.removeItem('SAP', false);
      this._selectedSap = { id: '', name: '' };
    }
  }

  @observable practiceSelectorDisabled = false;

  @observable isLoggedIn = false;

  @observable isLoading = true;

  @observable practices: PracticeResponse[] = [];

  @observable practiceOffers: PracticeOffersResponse[] = [];

  @observable practiceSpeciesBreeds: PracticeSpeciesBreeds[] = [];

  @observable vendorSpeciesBreeds: Record<string, VendorSpeciesBreeds> = {};

  @observable speciesMappingChanged = false;

  @observable globalModalProps: ModalProps = {
    show: false,
    onOk: () => {},
    onExit: () => {},
    id: MODAL_ID,
    okCaption: '',
  };

  @observable stepsCompleted: StepsCompleted = {};

  public _navigate: NavigateFunction = () => {};

  public init(navigate: NavigateFunction): void {
    this._navigate = navigate;
  }

  @computed
  public get userSaps(): Sap[] {
    return sortByField(this.root.apiStore.user?.saps || [], 'id');
  }

  @computed
  public get practicesRetrieved(): boolean {
    return this.practices.length > 0;
  }

  @computed
  public get practicesConnected(): boolean {
    return this.practices.some(p => isDefined((p.hospitalId)));
  }

  public toMyCsDashboard(): void {
    window.location.href = myCsUrl();
  }
  @action
  public setLoading(value: boolean): void {
    this.isLoading = value;
  }

  @action
  public setPractices(practices: PracticeResponse[]): void {
    const primary: PracticeResponse[] = [];
    const nonPrimary: PracticeResponse[] = [];
    practices.forEach(p => {
      if (p.isPrimary) primary.push(p); else nonPrimary.push(p);
    });
    this.practices = [...sortByField(primary, 'name1'), ...sortByField(nonPrimary, 'name1')];
  }

  @computed
  public get vendorBreedsDropdownValues(): Record<string, DropDownOption[]> {
    const result: Record<string, DropDownOption[]> = {};
    Object.values(this.vendorSpeciesBreeds).forEach(s => result[s.id] = (s?.breeds || []).map(b => ({ value: b.id, label: `${s?.name}, ${b.name}` })));
    return result;
  }


  @action
  public showOkCancelPopup(header: any, children: any, okCaption: string, cancelCaption: string, onOk: () => void, onCancel?: () => void,
      closeOnClickOutside = false, okAutoHide = true): void {
    const action = async (ok: boolean): Promise<void> => {
      runInAction(() => {
        this.globalModalProps.disabled = true;
      });
      if (ok) {
        if (okAutoHide) this.globalModalProps.show = false;
        await onOk();
      } else if (onCancel) {
        await onCancel();
      }
      runInAction(() => {
        this.globalModalProps.disabled = false;
        if (!ok || !okAutoHide) this.globalModalProps.show = false;
      });
    };

    this.globalModalProps = {
      id: MODAL_ID,
      cypressData: MODAL_ID,
      show: true,
      okCaption,
      cancelCaption,
      header,
      children,
      onOk: () => action(true),
      onCancel: () => action(false),
      onExit: () => action(false),
      onClickOutside: closeOnClickOutside ? () => action(false) : undefined,
    };
  }

  @action
  public showOkPopup(header: any, children: any, onOk?: () => void, okCaption?: string): void {
    const action = (): void => {
      runInAction(() => this.globalModalProps.show = false);
      onOk && onOk();
    };
    this.globalModalProps = {
      id: MODAL_ID,
      cypressData: MODAL_ID,
      show: true,
      okCaption: okCaption || 'OK',
      header,
      children,
      onClickOutside: onOk ? () => action() : undefined,
      onOk: action,
      onExit: action,
    };
  }

  public showLeavePagePopup(onOk: () => void): void {
    this.showOkCancelPopup(
      'Leave page?',
      React.createElement(MessageLeavePage),
      'Continue',
      'Cancel',
      onOk,
    );
  }

  public async signOut(): Promise<void> {
    this.selectedSap = null;
    for (const storeName of Object.keys(this.root)) {
      const store = this.root[storeName] as AbstractStore;
      if (!(store instanceof GlobalStore)) await store.signOut();
    }
  }
}
