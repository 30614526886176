import { observer } from 'mobx-react';
import { BaseRendererProps } from '../../types/react-props-types';
import SpotIcon from '../../components/SpotIcon/SpotIcon';
import React, { useMemo } from 'react';
import Select from 'react-select';
import { DropDownOption, type PracticeBreed, PracticeSpeciesBreeds, VendorSpeciesBreeds } from '../../types/species-breeds-types';
import { useRenderLoader, useStore } from '../../store/hooks';
import Grid, { SubrowElementProps } from '../../components/Grid/Grid';
import { runInAction } from 'mobx';
import Checkbox from '../../components/Checkbox/Checkbox';
import { isDefined } from '../../utils/object-utils';
import Spinner from '../../components/Spinner/Spinner';

interface ActionsType extends BaseRendererProps<PracticeSpeciesBreeds> {
  expandedRowIds?: string[];
  action: (row: any, index: any) => void;
}

const ExpandCollapseRenderer = observer((props: ActionsType) => {
  const { row, action, expandedRowIds, index } = props;
  const [loading, startUpdate] = useRenderLoader();

  if (loading) return (
    <Spinner size={20} noHint />
  );

  const iconPath = expandedRowIds && expandedRowIds.includes(row.id) ? 'caret-up' : 'caret-down';
  const iconHint = expandedRowIds && expandedRowIds.includes(row.id) ? 'collapse' : 'expand';

  const doAction = (): void => {
    startUpdate(() => action(row, index));
  };

  return (
    <div className="actions-cell">
      <SpotIcon className="expand-collapse-icon" path={iconPath} ariaLabel={`species-${iconPath}`} title={iconHint} onClick={doAction} cypressData={`expand-${row.id}-row`} />
    </div>
  );
});

const UnmappedBreedsRenderer = observer((props: BaseRendererProps<PracticeSpeciesBreeds> & { index: number }) => {
  const { index } = props;
  const store = useStore().manageBreedsStore;
  if (store.searchText !== '') return <>{store.mappedSpeciesBreeds[index].breeds.length} matching results</>;
  return <>{store.unmappedBreedsCounts[index] > 0 ? `${store.unmappedBreedsCounts[index]} unmapped` : ''}</>;
});

const VendorSpeciesRenderer = observer((props: BaseRendererProps<PracticeSpeciesBreeds> & { index: number }) => {
  const { row} = props;
  const { globalStore } = useStore();
  const vendorSpecies = row.vendorItemId ? globalStore.vendorSpeciesBreeds[row.vendorItemId] : undefined;
  return <>{vendorSpecies?.name}</>;
});

const VendorBreedRenderer = observer((props: BaseRendererProps<PracticeBreed> & { index: number, vendorSpecies?: VendorSpeciesBreeds }) => {
  const { row, vendorSpecies } = props;
  const { manageBreedsStore: store, globalStore } = useStore();

  const options = useMemo(
    () => vendorSpecies ? globalStore.vendorBreedsDropdownValues[vendorSpecies.id] : [],
    [globalStore.vendorBreedsDropdownValues, vendorSpecies]
  );
  const currentOption = useMemo(
    () => options.find(o => o.value === row.vendorItemId) || null,
    [options, row.vendorItemId]
  );

  const changeBreed = (option: DropDownOption | null) => {
    runInAction(() => {
      if (!isDefined(row.vendorItemId)) {
        row.dontHide = true;
      }
      [row.vendorItemId, row.vendorBreedName] = [option?.value, option?.label];
      store.hasUnsavedChanges = true;
    });
  };

  return (
    <Select
      className="select-search"
      aria-label={`select-search-${row.id}`}
      options={options}
      value={currentOption}
      placeholder="Select Breed"
      onChange={changeBreed}
      isClearable
    />
  );
});

export const SubRowRenderer = observer((props: SubrowElementProps<PracticeSpeciesBreeds>) => {
  const { row } = props;
  const store = useStore().manageBreedsStore;
  const initialSpecies = store.root.globalStore.practiceSpeciesBreeds.find(s => s.id === row.id);
  const totalBreeds = initialSpecies ? initialSpecies.breeds.length : '';

  const [loading, startUpdate] = useRenderLoader();

  const toggleUnmappedBreeds = () => {
    startUpdate(() => runInAction(() => store.showOnlyUnmappedBreeds[row.id] = !store.showOnlyUnmappedBreeds[row.id]));
  };

  return (
    <div key={`breeds-management-subrow-${row.id}`} className="subrow-wrapper">
      <div className="subrow-info-wrapper">
        <span className="displaying-label">
          Displaying {row.breeds.length} of {totalBreeds}
        </span>
        <Checkbox
          id={`breed-mapping-dropdown-${row.id}`}
          className="breed-mapping-dropdown"
          checked={store.showOnlyUnmappedBreeds[row.id]}
          disabled={store.searchText !== ''}
          loading={loading}
          onChange={() => toggleUnmappedBreeds()}
        >
          Show mapped breeds
        </Checkbox>
      </div>
      <Grid
        id={`breeds-management-grid-${row.id}`}
        className="breeds-management-subrow-grid"
        columns={store.subRowColDefs(row.id)}
        data={row.breeds}
        sorting={store.sortings[row.id]}
      />
    </div>
  );
});

export const SpeciesMappingChangedMessage = () => <>
  <p>We have mapped a portion of your Cornerstone breeds to Trupanion&apos;s breed list.</p>
  <p>Note that Canine mixed breeds have not been mapped, and there may be other breeds requiring manual mapping. Use this page to make additional mappings as needed.</p>
  <p>Once satisfied, click <strong>Update Cornerstone</strong> to send this information to your Cornerstone database.</p>
</>;

export const expandCollapseRenderer = (action: (row: PracticeSpeciesBreeds) => void) => {
  const expandCollapseRendererWrapper = (row: PracticeSpeciesBreeds, index: number, _id: string, expandedRowIds: string[] | undefined) => <ExpandCollapseRenderer row={row} index={index} expandedRowIds={expandedRowIds} action={action} />;
  return expandCollapseRendererWrapper;
};

export const unmappedBreedsRenderer = (row: PracticeSpeciesBreeds, index: number) => <UnmappedBreedsRenderer row={row} index={index} />;

export const vendorSpeciesRenderer = (row: PracticeSpeciesBreeds, index: number) => <VendorSpeciesRenderer row={row} index={index} />;

export const vendorBreedRenderer = (vendorSpecies?: VendorSpeciesBreeds) => {
  const vendorBreedRendererWrapper = (row: PracticeBreed, index: number) => <VendorBreedRenderer row={row} index={index} vendorSpecies={vendorSpecies} />;
  return vendorBreedRendererWrapper;
};
