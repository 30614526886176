import { chooseConfig, Env } from './app';

let _mycsUrl: string | undefined = undefined;

const mycsUrls: Record<Env, string> = {
  local: 'https://local.app:3000',
  dev: 'https://dev-mycs.testcosidexx.com',
  qa: 'https://qa-mycs.testcosidexx.com',
  stage: 'https://stage-mycs.cosidexx.com',
  prod: 'https://mycornerstone.idexx.com',
};

export const myCsUrl = (): string => {
  if (!_mycsUrl) {
    _mycsUrl = chooseConfig(mycsUrls);
  }
  return _mycsUrl;
};
