import React from 'react';
import { CustomElementProps } from '../../types/react-props-types';
import Checkbox from '../Checkbox/Checkbox';

const CHECKBOX_GROUP_TYPE_CLASSES = {
  expanded: 'spot-form__checkbox-group--expanded',
  horizontal: 'spot-form__field-group--horizontal',
} as const;

interface CheckboxGroupProps extends CustomElementProps {
  id: string;
  label?: string;
  type?: keyof typeof CHECKBOX_GROUP_TYPE_CLASSES;
  small?: boolean;
  values: Readonly<any[]>;
  displayField?: string;
  onChange: (indices: number[], addedIndex: number | undefined, removedIndex: number | undefined) => void;
  checkedIndices: number[];
  formatter?: (v: any) => string;
  disabled?: (index: number) => boolean;
  groupClass?: string;
  cypressData?: string;
}

const CheckboxGroup = (props: CheckboxGroupProps) => {
  const { id, label, type, small, values, displayField, onChange, formatter, className, checkedIndices, disabled, groupClass, cypressData } = props;

  const formatValue = (value) => (formatter ? formatter(value) : (displayField ? value[displayField] : value));
  const changeSelection = (index: number, checked: boolean): void => {
    if (checked) {
      onChange(checkedIndices.concat(index), index, undefined);
    } else {
      onChange(checkedIndices.filter(i => i !== index), undefined, index);
    }
  };

  return (
    <fieldset
      className={`spot-form__checkbox-group ${type ? CHECKBOX_GROUP_TYPE_CLASSES[type] : ''} ${className || ''}`}
      role="group" data-cy={cypressData}>
      {label && <legend className="spot-form__checkbox-group-label">{label}</legend>}
      <div className="spot-form__checkbox-group-inner">
        {values.map((value, index) => (
          <Checkbox
            id={`${id}__${index}`}
            className={groupClass}
            ariaLabel={formatValue(value)}
            key={displayField ? value[displayField] : value}
            checked={checkedIndices.includes(index)}
            disabled={(disabled && disabled(index)) || false}
            onChange={checked => changeSelection(index, checked)}
            small={small}
            cypressData={`${cypressData}-${formatValue(value)}-cb`}
          >
            {formatValue(value)}
          </Checkbox>
        ))}
      </div>
    </fieldset>
  );
};

export default CheckboxGroup;
