import { useStore } from '../../store/hooks';
import React, { useEffect } from 'react';
import { runInAction } from 'mobx';
import Checkbox from '../../components/Checkbox/Checkbox';
import './RemoveSpeciesMappingModalContent.scss';
import { observer } from 'mobx-react';

const RemoveSpeciesMappingModalContent = observer(() => {
  const { globalStore } = useStore();
  const modalProps = globalStore.globalModalProps;

  useEffect(() => {
    runInAction(() => modalProps.okDisabled = true);
  }, [modalProps]);

  return (
    <div className='remove-species-mapping-content_container'>
      <p>This action will unmap your Cornerstone species and its associated breeds from Trupanion&apos;s species and breeds list.</p>
      <Checkbox
        id="remove-species-mapping-cb"
        small
        checked={!modalProps.okDisabled}
        ariaLabel="remove-species-mapping"
        onChange={checked => runInAction(() => modalProps.okDisabled = !checked)}
        cypressData="remove-species-mapping-cb"
      >
        Do you wish to continue?
      </Checkbox>
    </div>
  );
});

export default RemoveSpeciesMappingModalContent;
