import { computed } from 'mobx';
import type { NavigateFunction } from 'react-router';
import RootStore from './RootStore';

export default abstract class AbstractStore {
  public readonly root: RootStore;

  constructor(rootStore: RootStore) {
    this.root = rootStore;
  }

  @computed
  public get sapId(): string {
    return this.root.globalStore.selectedSap.id;
  }

  public get navigate(): NavigateFunction {
    return this.root.globalStore._navigate;
  }

  @computed
  public get hasUnsavedChanges(): boolean {
    return this.root.globalStore._hasUnsavedChanges;
  }

  public set hasUnsavedChanges(value: boolean) {
    this.root.globalStore._hasUnsavedChanges = value;
  }

  public signOut(): void | Promise<void> {}
}
