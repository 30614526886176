import React from 'react';
import { BaseDataPointResponse } from '../types/common-types';
import GlobalStore from '../store/GlobalStore';

export const AgentIASError = () => <>
  <p>We were unable to retrieve data from Cornerstone.</p>
  <p>Please make sure all Cornerstone Database Services are running and then try again.</p>
</>;

export const AgentDataPointError = () => <>
  <p>We were unable to connect to the DataPoint agent on your Cornerstone server.</p>
  <p>Please contact Cornerstone Support at <strong>1-800-695-2877</strong> for assistance.</p>
</>;

export const AgentCSServerError = () => <>
  <p>We were unable to retrieve information from your Cornerstone server.</p>
  <p>Please contact Cornerstone Support at <strong>1-800-695-2877</strong> for assistance.</p>
</>;

export const backendCallWithDataPointErrorsHandling = async <T,>(globalStore: GlobalStore, callFn: () => Promise<BaseDataPointResponse<T>>, successFn: (context: T) => void, errorFn?: () => void): Promise<void> => {
  let response: BaseDataPointResponse<T> | undefined = undefined;
  let errorMessage: any = 'An unexpected error occurred, please try again.';
  let errorHeader = 'Connection Error';
  try {
    response = await callFn();
    if (response.responseCode === 6) errorMessage = React.createElement(AgentIASError);
    else if (response.responseCode === 1) errorMessage = React.createElement(AgentDataPointError);
    // -1 is an artificial status code for enrolled sites with DataPoint / IAS errors
    else if (response.responseCode === -1) errorMessage = React.createElement(AgentCSServerError);
    else {
      if (response.iasHttpStatus === 400) {
        errorHeader = 'Unsupported Cornerstone Version';
        errorMessage = 'Your current Cornerstone version does not support integration with Trupanion.';
      } else if (response.iasHttpStatus === 200) {
        successFn(response.context);
        errorMessage = undefined;
      }
    }
  } catch {
    // ignore
  }
  if (errorMessage) {
    globalStore.showOkPopup(errorHeader, errorMessage);
    errorFn && errorFn();
  }
};
