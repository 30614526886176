import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../store/hooks';
import Checkbox from '../../components/Checkbox/Checkbox';
import { runInAction } from 'mobx';
import './DisconnectPracticeModalContent.scss';

interface DisconnectPracticeModalContentProps {
  practiceName: string;
}

const DisconnectPracticeModalContent = observer((props: DisconnectPracticeModalContentProps) => {
  const { globalStore } = useStore();
  const modalProps = globalStore.globalModalProps;

  useEffect(() => {
    runInAction(() => modalProps.okDisabled = true);
  }, [modalProps]);

  return (
    <div className='disconnect-practice-content_container'>
      <p>You have selected to disconnect the Trupanion integration for {props.practiceName}</p>
      <Checkbox
        id="disconnect-practice-cb"
        small
        checked={!modalProps.okDisabled}
        ariaLabel="disconnect-practice"
        onChange={checked => runInAction(() => modalProps.okDisabled = !checked)}
        cypressData="disconnect-practice-cb"
      >
        Do you wish to continue?
      </Checkbox>
    </div>
  );
});

export default DisconnectPracticeModalContent;
