import { action, observable, runInAction } from 'mobx';
import AbstractStore from '../../store/AbstractStore';

interface Loaders {
  page: boolean;
}

export default class DashboardStubStore extends AbstractStore {
  @observable loaders: Loaders = {
    page: true,
  };

  public firstShow = true;

  @action
  public async init(): Promise<void> {
    if (!this.firstShow) {
      this.root.globalStore.toMyCsDashboard();
      return;
    }
    runInAction(() => {
      this.loaders = { page: true };
      this.root.globalStore.setLoading(true);
    });
    await this.root.apiStore.fetchCurrentUserDetails();
    const sap = (this.root.globalStore.userSaps || []).find(sap => sap.id === this.root.globalStore.selectedSap.id);
    if (!sap) {
      this.root.globalStore.toMyCsDashboard();
    } else {
      runInAction(() => this.root.globalStore.selectedSap = sap);
      await this.getPracticesAndNavigate();
    }
    this.firstShow = false;
    runInAction(() => {
      this.loaders.page = false;
      this.root.globalStore.setLoading(false);
    });
  }

  @action
  private async getPracticesAndNavigate(): Promise<void> {
    this.root.globalStore.setPractices(await this.root.apiStore.fetchPractices(this.sapId));
    if (this.root.globalStore.practices.some(p => p.integrationEnabled)) {
      runInAction(() => {
        this.root.globalStore.stepsCompleted = {
          practices: true,
          offers: true,
        };
      });
      this.navigate('/species');
    } else {
      this.navigate('/connect-practice');
    }
  }
}
