import React from 'react';
import { CustomElementProps } from '../../types/react-props-types';
import SpotIcon, { SpotIconPath } from '../SpotIcon/SpotIcon';
import './SpotIconButton.scss';
import Button from '../Button/Button';
import Spinner from '../Spinner/Spinner';

interface SpotIconButtonProps extends CustomElementProps {
  path: SpotIconPath;
  title?: string;
  onClick?: () => void;
  cypressData?: string;
  disabled?: boolean;
  loading?: boolean;
}

const SpotIconButton = (props: SpotIconButtonProps) => {
  const { path, title, onClick, className, id, ariaLabel, cypressData, disabled, loading } = props;
  if (loading) return <Spinner size={18} />;
  return (
    <Button
      id={id}
      className={`spot-icon-button ${className || ''}`}
      type="link"
      size="small"
      disabled={disabled}
      ariaLabel={ariaLabel || title || path}
      onClick={() => !disabled && onClick && onClick()}
      cypressData={cypressData}
    >
      <SpotIcon className={`${disabled ? 'disabled' : ''}`} path={path} title={title} />
    </Button>
  );
};

export default SpotIconButton;
