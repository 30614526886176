import React from 'react';
import { CustomElementProps } from '../../types/react-props-types';
import SpotIcon from '../SpotIcon/SpotIcon';
import './DropDown.scss';

interface DropDownProps extends CustomElementProps {
  groupClassName?: string;
  values: readonly any[];
  disabledIndices?: any[];
  displayField?: string;
  disabled?: boolean;
  label?: string;
  error?: boolean;
  onChange: (index: number) => void;
  formatter?: (v: any) => string;
  selectedIndex: number;
  cypressData?: string;
}

const DropDown = (props: DropDownProps) => {
  const { id, className, values, disabledIndices, selectedIndex, formatter, disabled, onChange, displayField, error, label, groupClassName, cypressData } = props;

  const formatValue = (value) => (formatter ? formatter(value) : (displayField ? value[displayField] : value));

  return (
    <div className={`spot-form__field-group ${error ? 'spot-form--error' : ''} ${groupClassName || ''}`}>
      {label && <label className="spot-form__label" htmlFor={id}>{label}</label>}
      <div className={`spot-form__select ${className || ''}`}>
        <select
          id={id}
          role="drop-down"
          className="spot-form__select-input"
          disabled={disabled}
          value={selectedIndex === undefined ? "-1" : selectedIndex}
          onChange={e => onChange(parseInt(e.target.value))}
          data-cy={cypressData}
        >
          {(selectedIndex === undefined || selectedIndex === -1) && (<option value="-1">&nbsp;</option>)}
          {values.map((value, index) => (
            <option
              className="spot-form__select-option"
              value={index}
              key={displayField ? value[displayField] : value}
              disabled={disabledIndices && disabledIndices.includes(index)}
              data-cy={`${cypressData}-${formatValue(value)}-option`}
            >
              {formatValue(value)}
            </option>
          ))}
        </select>
        <div className="spot-form__select-inner" />
        <span className="spot-form__select-open-indicator">
          <SpotIcon className="spot-form__select-open-icon" path="caret-down" />
        </span>
      </div>
    </div>
  );
};

export default DropDown;
