import AbstractStore from '../../store/AbstractStore';
import { action, computed, observable, runInAction } from 'mobx';
import type { PracticeBreed, PracticeSpeciesBreeds } from '../../types/species-breeds-types';
import { isDefined, sortByField } from '../../utils/object-utils';
import { GridColDef, SortingObservable } from '../../components/Grid/Grid';
import { expandCollapseRenderer, SpeciesMappingChangedMessage, unmappedBreedsRenderer, vendorBreedRenderer, vendorSpeciesRenderer } from './renderers';
import { PRACTICES_PATH, SPECIES_BREEDS_PATH } from '../../store/constants';
import { regexToSearchWithWildcards } from '../../utils/search-utils';
import { isActualSpeciesId } from '../../utils/species-utils';
import React from 'react';
import { backendCallWithDataPointErrorsHandling } from '../../utils/data-point-utils';
import { BaseDataPointResponse } from '../../types/common-types';

interface Loaders {
  save?: boolean;
}

export default class ManageBreedsStore extends AbstractStore {
  @observable expandedRowIds: string[] = [];

  @observable showOnlyUnmappedBreeds: Record<string, boolean> = {};

  @observable sortings: Record<string, SortingObservable<PracticeBreed>> = {};

  @observable loaders: Loaders = {};

  @observable searchText = '';

  @computed
  public get speciesBreedsShowedSections(): PracticeSpeciesBreeds[] {
    return this.root.globalStore.practiceSpeciesBreeds.filter(s => isActualSpeciesId(s.vendorItemId));
  }

  @computed
  public get mappedSpeciesBreeds(): PracticeSpeciesBreeds[] {
    return this.speciesBreedsShowedSections.map(s => {
      let breeds = this.searchText !== '' || this.showOnlyUnmappedBreeds[s.id] ? s.breeds : s.breeds.filter(b => !isDefined(b.vendorItemId) || b.dontHide);
      if (this.searchText !== '') {
        const regex = regexToSearchWithWildcards(this.searchText);
        breeds = breeds.filter(b => regex.test(b.id) || regex.test(b.name) || isDefined(b.vendorBreedName) && regex.test(b.vendorBreedName));
      }
      const sorting = this.sortings[s.id];
      if (sorting?.field) {
        breeds = sortByField(breeds, sorting.field);
        if (sorting.order === 'DESC') {
          breeds = breeds.reverse();
        }
      }
      return { ...s, breeds };
    });
  }

  @computed
  public get unmappedBreedsCounts(): number[] {
    return this.mappedSpeciesBreeds.map( s => s.breeds.filter(b => !isDefined(b.vendorItemId)).length);
  }

  @computed
  public get practiceSpeciesVendorSpeciesIdMap(): Record<string, string | undefined> {
    const result: Record<string, string | undefined> = {};
    this.mappedSpeciesBreeds.forEach(s => {
      result[s.id] = s.vendorItemId;
    });
    return result;
  }

  @computed
  public get colDefs(): GridColDef<PracticeSpeciesBreeds>[] {
    return [
      { headerName: '', field: '', key: 'expand', renderer: expandCollapseRenderer(this.switchExpandedRow) },
      { headerName: 'Species ID', field: 'id' },
      { headerName: 'Description', field: 'name' },
      { headerName: 'Trupanion Species', field: '', renderer: vendorSpeciesRenderer },
      { headerName: '', field: '', key: 'unmappedCount', renderer: unmappedBreedsRenderer },
    ];
  }

  public subRowColDefs(speciesId: string): GridColDef<PracticeBreed>[] {
    const vendorSpeciesId = this.practiceSpeciesVendorSpeciesIdMap[speciesId];
    const vendorSpecies = isDefined(vendorSpeciesId) ? this.root.globalStore.vendorSpeciesBreeds[vendorSpeciesId] : undefined;
    return [
      { headerName: 'Breed ID', field: 'id', sortable: true },
      { headerName: 'Description', field: 'name', sortable: true },
      { headerName: 'Trupanion Breed', field: '', renderer: vendorBreedRenderer(vendorSpecies) },
    ];
  }

  @action.bound
  public switchExpandedRow(row: PracticeSpeciesBreeds): void {
    if (this.expandedRowIds.includes(row.id)) {
      this.expandedRowIds = [];
    } else {
      this.expandedRowIds = [row.id];
    }
  }

  @action
  public init(): void {
    this.hasUnsavedChanges = false;
    this.expandedRowIds = [];
    this.sortings = {};
    this.searchText = '';
    this.mappedSpeciesBreeds.forEach(s => {
      this.sortings[s.id] = { field: 'name', order: 'ASC' };
    });
    if (this.root.globalStore.speciesMappingChanged) {
      this.root.globalStore.showOkPopup(
        'Manage Breeds',
        React.createElement(SpeciesMappingChangedMessage),
      );
    }
  }

  @action
  public async saveBreedsMapping(): Promise<void> {
    try {
      runInAction(() => this.loaders.save = true);
      await this.root.apiStore.backendPost<PracticeSpeciesBreeds>(SPECIES_BREEDS_PATH, {
        action: 'UPDATE_BREEDS_MAPPING',
        sapId: this.sapId,
        speciesBreeds: this.speciesBreedsShowedSections,
      });
      runInAction(() => this.hasUnsavedChanges = false);
      await backendCallWithDataPointErrorsHandling(
        this.root.globalStore,
        () => this.root.apiStore.backendPost<BaseDataPointResponse<any>>(PRACTICES_PATH, {
          action: 'SETUP_OFFER_VENDOR',
          sapId: this.sapId,
        }),
        () => this.root.globalStore.showOkPopup('Cornerstone Updated', 'Your changes have been sent to your Cornerstone server.'),
      );
    } catch (e: any) {
      this.showGenericError();
    } finally {
      runInAction(() => this.loaders.save = false);
    }
  }

  @action
  public close(): void {
    this.root.globalStore.practiceSpeciesBreeds.forEach(s => {
      s.breeds.forEach(b => b.dontHide = false);
    });
  }

  private showGenericError(): void {
    this.root.globalStore.showOkPopup('Connection Error', 'An unexpected error occurred, please try again.');
  }
}
