import AbstractStore from '../../store/AbstractStore';
import { action, observable, runInAction } from 'mobx';
import { backendCallWithDataPointErrorsHandling } from '../../utils/data-point-utils';
import { SPECIES_BREEDS_PATH } from '../../store/constants';
import type { PracticeSpeciesBreeds, VendorSpeciesBreeds } from '../../types/species-breeds-types';
import { GridColDef } from '../../components/Grid/Grid';
import { actionsRenderer, MessageMapBreeds, vendorSpeciesRenderer } from './renderers';
import { arrayToMap, isDefined, sortByField } from '../../utils/object-utils';
import React from 'react';
import { isActualSpeciesId } from '../../utils/species-utils';
import RemoveSpeciesMappingModalContent from './RemoveSpeciesMappingModalContent';

interface Loaders {
  page?: boolean;
  retrieve?: boolean;
  save?: boolean;
  saveSingle?: boolean;
}

export default class ManageSpeciesStore extends AbstractStore {
  @observable loaders: Loaders = {};

  @observable singleItemEditMode = false;

  @observable currentItemIndex: number | undefined = undefined;

  @observable currentValue: string | undefined = undefined;

  public colDefs: GridColDef<PracticeSpeciesBreeds>[] = [
    { headerName: 'Species ID', field: 'id' },
    { headerName: 'Description', field: 'name' },
    { headerName: 'Trupanion Species', field: '', renderer: vendorSpeciesRenderer },
    { headerName: 'Actions', field: '', renderer: actionsRenderer },
  ];

  @action
  public async init(): Promise<void> {
    runInAction(() => {
      this.currentItemIndex = undefined;
      this.root.globalStore.speciesMappingChanged = false;
    });
    if (this.root.globalStore.practiceSpeciesBreeds.length === 0) {
      try {
        runInAction(() => this.loaders.page = true);
        const response = await this.root.apiStore.fetchSpeciesAndBreeds(this.sapId);
        this.setVendorSpeciesBreeds(response.vendorSpeciesBreeds);
        this.setPracticeSpeciesBreeds(response.practiceSpeciesBreeds);
        this.updateEditMode();
      } catch (e: any) {
        console.error(e);
        this.showGenericError();
      } finally {
        runInAction(() => this.loaders.page = false);
      }
    }
  }

  @action
  public async retrieveSpeciesAndBreeds(): Promise<void> {
    runInAction(() => this.loaders.retrieve = true);
    await backendCallWithDataPointErrorsHandling<PracticeSpeciesBreeds[]>(
      this.root.globalStore,
      () => this.root.apiStore.backendPost(SPECIES_BREEDS_PATH, {
        action: 'UPDATE_FROM_PRACTICE',
        sapId: this.sapId,
      }),
      context => this.updatePracticeSpeciesBreedsFromLocal(context),
    );
    runInAction(() => this.loaders.retrieve = false);
  }

  @action
  public async saveAndRedirectToManageBreeds(): Promise<void> {
    if (this.singleItemEditMode) {
      runInAction(() => this.root.globalStore.stepsCompleted.species = true);
      this.navigate('/breeds');
      return;
    }

    try {
      runInAction(() => this.loaders.save = true);
      const response = await this.root.apiStore.backendPost<PracticeSpeciesBreeds[]>(SPECIES_BREEDS_PATH, {
        action: 'SAVE_SPECIES_MAPPING',
        sapId: this.sapId,
        speciesBreeds: this.root.globalStore.practiceSpeciesBreeds,
      });
      this.setPracticeSpeciesBreeds(response);
      this.updateEditMode();
      runInAction(() => {
        this.root.globalStore.stepsCompleted.species = true;
        if (this.root.globalStore.practiceSpeciesBreeds.some(s => isActualSpeciesId(s.vendorItemId))) {
          this.root.globalStore.speciesMappingChanged = true;
        }
      });
      this.navigate('/breeds');
    } catch (e: any) {
      this.showGenericError();
    } finally {
      runInAction(() => this.loaders.save = false);
    }
  }

  @action
  public async saveItem(index: number): Promise<void> {
    const currentItem = this.root.globalStore.practiceSpeciesBreeds[index];
    if (currentItem.vendorItemId !== this.currentValue) {
      if (isActualSpeciesId(currentItem.vendorItemId)) {
        this.root.globalStore.showOkCancelPopup(
          'Unmap Species?',
          React.createElement(RemoveSpeciesMappingModalContent),
          'Continue',
          'Cancel',
          () => this.doSaveItem(currentItem, index),
          undefined,
          false,
          true,
        );
      } else {
        await this.doSaveItem(currentItem, index);
      }
    } else {
      runInAction(() => this.currentItemIndex = undefined);
    }
  }

  @action
  public setVendorSpeciesBreeds(vendorSpeciesBreeds: VendorSpeciesBreeds[]): void {
    this.root.globalStore.vendorSpeciesBreeds = arrayToMap(vendorSpeciesBreeds.map(s => ({
      ...s,
      breeds: sortByField(s.breeds, 'name'),
    })), 'id');
  }

  @action
  public setPracticeSpeciesBreeds(speciesBreeds: PracticeSpeciesBreeds[]): void {
    speciesBreeds.forEach(s => this.updateBreedsProps(s));
    this.root.globalStore.practiceSpeciesBreeds = sortByField(speciesBreeds, 'name');
  }

  @action
  public updateBreedsProps(singleSpeciesBreeds: PracticeSpeciesBreeds): void {
    singleSpeciesBreeds.breeds.forEach(b => {
      if (isDefined(singleSpeciesBreeds.vendorItemId)) {
        const dropdownIndex = this.root.globalStore.vendorBreedsDropdownValues[singleSpeciesBreeds.vendorItemId].findIndex(vb => vb.value === b.vendorItemId);
        b.vendorBreedName = dropdownIndex > 0 ? this.root.globalStore.vendorBreedsDropdownValues[singleSpeciesBreeds.vendorItemId][dropdownIndex].label : undefined;
      } else {
        b.vendorBreedName = undefined;
      }
    });
  }

  @action
  public updatePracticeSpeciesBreedsFromLocal(speciesBreeds: PracticeSpeciesBreeds[]): void {
    const speciesMap = arrayToMap(this.root.globalStore.practiceSpeciesBreeds, 'id');
    speciesBreeds.forEach(s => {
      const breedsMap = arrayToMap(speciesMap[s.id]?.breeds || [], 'id');
      s.breeds.forEach(b => b.vendorItemId = breedsMap[b.id]?.vendorItemId);
      s.vendorItemId = speciesMap[s.id]?.vendorItemId;
      s.editDisabled = isDefined(speciesMap[s.id]?.vendorItemId);
      this.updateBreedsProps(s);
    });
    this.root.globalStore.practiceSpeciesBreeds = sortByField(speciesBreeds, 'name');
  }

  @action
  private updateEditMode(): void {
    this.singleItemEditMode = this.root.globalStore.practiceSpeciesBreeds.some(s => isDefined(s.vendorItemId));
    this.hasUnsavedChanges = false;
  }

  @action
  private async doSaveItem(currentItem: PracticeSpeciesBreeds, index: number): Promise<void> {
    try {
      runInAction(() => this.loaders.saveSingle = true);
      const response = await this.root.apiStore.backendPost<PracticeSpeciesBreeds>(SPECIES_BREEDS_PATH, {
        action: 'SAVE_SINGLE_SPECIES_MAPPING',
        sapId: this.sapId,
        singleSpecies: {
          ...currentItem,
          vendorItemId: this.currentValue,
        },
      });
      this.updateBreedsProps(response);
      runInAction(() => {
        this.root.globalStore.practiceSpeciesBreeds[index] = response;
        this.currentItemIndex = undefined;
        if (isActualSpeciesId(this.currentValue)) {
          this.root.globalStore.speciesMappingChanged = true;
        }
      });
      this.root.globalStore.showOkPopup(
        'Map Breeds',
        React.createElement(MessageMapBreeds),
      );
    } catch (e: any) {
      this.showGenericError();
    } finally {
      runInAction(() => this.loaders.saveSingle = false);
    }
  }

  private showGenericError(): void {
    this.root.globalStore.showOkPopup('Connection Error', 'An unexpected error occurred, please try again.');
  }
}
