import React from 'react';
import { BaseRendererProps } from '../../types/react-props-types';
import { AutomatedPopupsValues, ManualButtonsValues, PracticeOffersResponse } from '../../types/practices-types';
import CheckboxGroup from '../../components/CheckboxGroup/CheckboxGroup';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import RadioButtonGroup from '../../components/RadioButtonGroup/RadioButtonGroup';
import { useStore } from '../../store/hooks';

const AutomatedPopupsRenderer = observer((props: BaseRendererProps<PracticeOffersResponse>) => {
  const { row, index } = props;
  const store = useStore().offerSetupStore;

  return (
    <RadioButtonGroup
      id={`practice-automated-popups-group-${index}`}
      groupName={`practice-automated-popups-group-${index}`}
      groupClass="automated-popups-editors"
      type="horizontal"
      values={AutomatedPopupsValues}
      onChange={idx => runInAction(() => {
        row.automatedPopups = AutomatedPopupsValues[idx];
        store.hasUnsavedChanges = true;
      })}
      checkedIndex={AutomatedPopupsValues.findIndex(v => v === row.automatedPopups)}
    />
  );
});

const ManualButtonRenderer = observer((props: BaseRendererProps<PracticeOffersResponse>) => {
  const { row, index } = props;
  const store = useStore().offerSetupStore;
  const changeIndices = (indices: number[], addedIndex: number | undefined, removedIndex: number | undefined): void => {
    runInAction(() => {
      if (addedIndex == 0) row.manualButtons = [...ManualButtonsValues];
      else if (removedIndex == 0) row.manualButtons = [];
      else row.manualButtons = indices.map(idx => ManualButtonsValues[idx]);
      store.hasUnsavedChanges = true;
    });
  };

  const isDisabled = (index: number): boolean => {
    if (ManualButtonsValues[index] === 'All') {
      return !row.manualButtons.includes('All') && row.manualButtons.some(v => v !== 'All');
    } else {
      return row.manualButtons.includes('All');
    }
  };

  return (
    <CheckboxGroup
      id={`practices-manual-buttons-group-${index}`}
      groupClass="manual-buttons-editors"
      type="horizontal"
      values={ManualButtonsValues}
      onChange={changeIndices}
      checkedIndices={row.manualButtons.map(v => ManualButtonsValues.findIndex(bv => bv === v))}
      disabled={isDisabled}
    />
  );
});

export const automatedPopupsRenderer = (row, index) => <AutomatedPopupsRenderer row={row} index={index} />;

export const manualButtonRenderer = (row: PracticeOffersResponse, index: number) => <ManualButtonRenderer row={row} index={index} />;
