import { observer } from 'mobx-react';
import { useStore } from '../../store/hooks';
import React, { useEffect } from 'react';
import { runInAction } from 'mobx';
import './EnterHospitalIdModalContent.scss';
import TextBox from '../../components/TextBox/TextBox';

const guidRegex = /^([\dA-Fa-f]{8}-[\dA-Fa-f]{4}-[\dA-Fa-f]{4}-[\dA-Fa-f]{4}-[\dA-Fa-f]{12})$/;

const EnterHospitalIdModalContent = observer(() => {
  const { connectPracticeStore: store, globalStore } = useStore();
  const modalProps = globalStore.globalModalProps;

  useEffect(() => {
    runInAction(() => {
      store.hospitalId = '';
      modalProps.okDisabled = true;
    });
  }, [modalProps, store]);

  useEffect(() => {
    runInAction(() => modalProps.okDisabled = !guidRegex.test(store.hospitalId));
  }, [modalProps, store.hospitalId]);

  return (
    <div className='enter-hospital-id-content_container'>
      <p>Please enter your practice&apos;s unique ID.</p>
      <TextBox
        placeholder="Enter your Trupanion Unique ID and click Submit"
        ariaLabel="hospital-id-textbox"
        value={store.hospitalId}
        onChange={v => runInAction(() => store.hospitalId = v)}
        cypressData="hospital-id-textbox"
      />
    </div>
  );
});

export default EnterHospitalIdModalContent;
