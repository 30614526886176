import React from 'react';
import Button from '../../components/Button/Button';
import { BaseRendererProps } from '../../types/react-props-types';
import { type PracticeHospitalResponse, PracticeResponse } from '../../types/practices-types';
import { useStore } from '../../store/hooks';
import { observer } from 'mobx-react';
import { isDefined } from '../../utils/object-utils';
import Pill from '../../components/Pill/Pill';
import RadioButton from '../../components/RadioButton/RadioButton';
import { runInAction } from 'mobx';
import Grid, { SubrowElementProps } from '../../components/Grid/Grid';
import Popover from '../../components/Popover/Popover';
import DisconnectPracticeModalContent from './DisconnectPracticeModalContent';
import EnterHospitalIdModalContent from './EnterHospitalIdModalContent';

const TrupanionHospitalRenderer = observer((props: BaseRendererProps<PracticeResponse> & { index: number }) => {
  const { row, index } = props;
  const { connectPracticeStore: store, globalStore } = useStore();
  const { loaders } = store;
  const primaryPractice = globalStore.practices.find(p => p.isPrimary);

  const confirmDisconnectPractice = () => {
    globalStore.showOkCancelPopup(
      'Disconnect Practice',
      <DisconnectPracticeModalContent practiceName={row.name1} />,
      'Continue',
      'Cancel',
      () => store.updatePracticesProperties(index, undefined),
      undefined,
      false,
      true,
    );
  };

  const addManually = () => {
    globalStore.showOkCancelPopup(
      'Enter Your Hospital\'s Unique ID',
      <EnterHospitalIdModalContent />,
      'Submit',
      'Cancel',
      () => store.getTrupanionHospitalById(index),
      undefined,
      false,
      true,
    );
  };

  if (isDefined(row.hospitalId)) {
    return <>
      <Popover
        id={`practice-hospital-id-${row.id}`}
        position="bottom-left"
        inset="small"
        element={<Pill type="positive" small cypressData={`connected-practice-${row.id}-btn`}>Connected</Pill>}
        cypressData={`practice-hospital-id-${row.id}-popover`}
      >
        {row.hospitalId}
      </Popover>
      <Button
        icon="cancel"
        size="small"
        type="link"
        onClick={() => confirmDisconnectPractice()}
        loading={loaders.disconnect}
        disabled={loaders.connect || loaders.disconnect}
        cypressData={`disconnect-practice-${row.id}-btn`}
      >
        Disconnect
      </Button>
    </>;
  }
  return <>
    {isDefined(primaryPractice) && row.id !== primaryPractice.id && isDefined(primaryPractice.hospitalId) && (
      <Button
        icon="home"
        size="small"
        type="link"
        onClick={() => store.updatePracticesProperties(index, primaryPractice?.hospitalId)}
        loading={loaders.connect}
        disabled={loaders.connect || loaders.disconnect}
        cypressData={`use-primary-${row.id}-btn`}
      >
        Use Primary
      </Button>
    )}
    <Button
      icon="show"
      size="small"
      type="link"
      disabled={isDefined(loaders.searchHospitalIndex) || isDefined(store.lookupPracticeIndex)}
      loading={loaders.searchHospitalIndex === index}
      onClick={() => store.searchForTrupanionHospital(row, index)}
      cypressData={`lookup-${row.id}-btn`}
    >
      Look Up
    </Button>
    <Button
      icon="add"
      size="small"
      type="link"
      disabled={!store.addManuallyEnabled[row.id] || isDefined(loaders.getHospitalByIdIndex) || isDefined(store.lookupPracticeIndex)}
      loading={loaders.getHospitalByIdIndex === index}
      cypressData={`add-manually-${row.id}-btn`}
      onClick={() => addManually()}
    >
      Add Manually
    </Button>
  </>;
});

const SubrowPracticeNameRenderer = observer((props: BaseRendererProps<PracticeHospitalResponse>) => {
  const { row, index } = props;
  const store = useStore().connectPracticeStore;
  return (
    <RadioButton
      id={`practice-name__${index}`}
      className="practice-name-rb"
      groupName="practice-name-group"
      checked={store.subrowPracticeIndex === index}
      onChange={checked => checked && runInAction(() => store.subrowPracticeIndex = index)}
      cypressData={`practice-name-rb-${index}`}
    >
      {row.name}
    </RadioButton>
  );
});

const SubrowActionsRenderer = observer((props: BaseRendererProps<PracticeHospitalResponse> & { baseRowIndex: number }) => {
  const { row, index, baseRowIndex } = props;
  const store = useStore().connectPracticeStore;
  return <>
    <Button
      type="link"
      icon="refresh-redo"
      ariaLabel="connect-trupanion"
      disabled={store.subrowPracticeIndex !== index}
      onClick={() => store.connectHospitalId(baseRowIndex, row.hospitalId)}
      loading={store.loaders.connect}
      cypressData={`connect-trupanion-${index}-btn`}
    >
      Connect
    </Button>
  </>;
});

export const SubRowRenderer = observer((props: SubrowElementProps<PracticeResponse>) => {
  const { row, index } = props;
  const store = useStore().connectPracticeStore;

  return (
    <div key={`connect-practice-subrow-${row.id}`} className="subrow-wrapper">
      <div className="subrow-info-wrapper">
        <span className="displaying-label">
          Look Up found {store.hospitalOptions.length} matches
        </span>
        <Button
          className="cancel-btn"
          ariaLabel="cancel-connecting"
          type="link"
          size="small"
          onClick={() => store.cancelLookup(index)}
          cypressData="cancel-connecting-btn"
        >
          Cancel
        </Button>
      </div>
      <Grid
        id={`connect-practice-grid-${row.id}`}
        className="connect-practice-subrow-grid"
        columns={store.subRowColDefs(index)}
        data={store.hospitalOptions}
      />
    </div>
  );
});

export const nameRenderer = (row: PracticeResponse) =>
  [row.name1, row.name2, row.isPrimary ? '(Primary)' : ''].filter(p => p).join(' ');

export const addressRenderer = (row: PracticeResponse) =>
  [row.address1, row.address2, row.city, row.stateProvince, row.postalCode].filter(p => p).join(' ');

export const trupanionHospitalRenderer = (row: PracticeResponse, index: number) => <TrupanionHospitalRenderer row={row} index={index} />;

export const subrowPracticeNameRenderer = (row: PracticeHospitalResponse, index: number) => <SubrowPracticeNameRenderer row={row} index={index} />;

export const subrowActionsRenderer = (baseRowIndex: number) => {
  const subrowActionsRendererWrapper = (row: PracticeHospitalResponse, index: number) => <SubrowActionsRenderer row={row} index={index} baseRowIndex={baseRowIndex} />;
  return subrowActionsRendererWrapper;
};
