import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import './OfferSetup.scss';
import { useBlocker, useStore } from '../../store/hooks';
import Spinner from '../../components/Spinner/Spinner';
import Grid from '../../components/Grid/Grid';
import type { Transition } from 'history';

const OfferSetup = observer(() => {
  const navigate = useNavigate();
  const { offerSetupStore: store, globalStore } = useStore();
  const { loaders, colDefs, filteredPracticeOffers, hasUnsavedChanges } = store;

  useEffect(() => {
    void store.init();
  }, [store]);

  useBlocker(useCallback((tx: Transition): void => {
    if (hasUnsavedChanges) {
      globalStore.showLeavePagePopup(() => tx.retry());
    } else {
      tx.retry();
    }
  }, [globalStore, hasUnsavedChanges]));

  return (
    <div className='offer-setup_wrapper'>
      <div className="page-heading">
        <div className='heading--level-1'>Setup your Trupanion Exam Day Offer</div>
        <div className='spot-typography__text--tertiary'>
          Select which Cornerstone window(s) to enable the Offer feature.
        </div>
      </div>
      {loaders.page
        ? <Spinner />
        : <>
          <Button
            className="refresh-practices-btn"
            icon="refresh-redo"
            type="link"
            size="small"
            disabled={loaders.retrieve}
            onClick={() => store.retrievePractices()}
            cypressData="refresh-practices-btn"
          >
            Refresh Practices
          </Button>
          {loaders.retrieve && <Spinner className="block-spinner" size={24} text="One moment while we retrieve your practices from your local Cornerstone Server."/>}
          {!loaders.retrieve && (
            <Grid
              id="practices-grid"
              columns={colDefs}
              data={filteredPracticeOffers}
            />
          )}
          <div className="offer-setup_actions">
            <Button type="link" size="small" className="back" onClick={() => navigate(-1)} cypressData="back-btn">Back</Button>
            <div className="main-buttons">
              <Button type="secondary" size="small" cypressData="bashboard-btn" onClick={() => navigate('/')}>Dashboard</Button>
              <Button
                type="primary"
                size="small"
                disabled={loaders.save}
                loading={loaders.save}
                cypressData="species-breeds-btn"
                onClick={() => store.saveOffersAndNavigateToSpeciesBreeds()}
              >
                Manage Species/Breeds
              </Button>
            </div>
          </div>
        </>
      }
    </div>
  );
});

export default OfferSetup;
