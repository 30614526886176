import React from 'react';
import { CustomElementProps } from '../../types/react-props-types';

interface RadioButtonProps extends CustomElementProps {
  id: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  role?: never;
  small?: boolean;
  cypressData?: string;
  groupName?: string;
}

const RadioButton = (props: RadioButtonProps) => {
  const { id, checked, disabled, onChange, className, children, ariaLabel, small, groupName, cypressData } = props;
  return (
    <label className={`spot-form__radio ${small ? 'spot-form__radio--small' : ''} ${className || ''}`} htmlFor={id}>
      <input
        id={id}
        name={groupName}
        type="radio"
        role="radio"
        aria-label={ariaLabel}
        className="spot-form__radio-input"
        checked={checked || false}
        disabled={disabled}
        onChange={() => onChange && !disabled && onChange(!checked)}
        data-cy={cypressData}
      />
      <span className="spot-form__radio-inner">
        <span className="spot-form__radio-visual"></span>
        <span className="spot-form__radio-label ">{children}</span>
      </span>
    </label>
  );
};

export default RadioButton;
