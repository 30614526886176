export default interface Practices {
  instance: Instance;
  countryCode: string;
  practices: PracticeResponse[];
}

export interface Instance {
  pimsSiteId: string;
  apiAgentHost: string;
  apiAgentPort: string;
  timeZone: string;
}

export interface PracticeResponse {
  id: string;
  name1: string;
  name2: string;
  address1: string;
  address2: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  isPrimary: boolean;
  phone?: string;
  hospitalId?: string;
  integrationEnabled?: boolean;
}

export interface PracticeHospitalResponse {
  name: string;
  hospitalId: string;
  address: string;
  phone: string;
}

export const AutomatedPopupsValues = ['Both', 'Check-In', 'Invoice'] as const;
export const ManualButtonsValues = ['All', 'Check-In', 'Estimate', 'Invoice', 'PVL'] as const;
export interface PracticeOffersResponse {
  practiceId: string;
  practiceName: string;
  automatedPopups: typeof AutomatedPopupsValues[number],
  manualButtons: (typeof ManualButtonsValues[number])[],
}
