import React, { ReactElement } from 'react';
import './Grid.scss';
import Checkbox from '../Checkbox/Checkbox';
import Spinner from '../Spinner/Spinner';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';

export interface GridColDef<T = any> {
  headerName: string;
  key?: string;
  field: string & keyof T | string;
  renderer?: (row: T, index: number, tableId: string, expandedRowIds: string[] | undefined) => any;
  checked?: boolean | undefined;
  setChecked?: (newValue: boolean) => void;
  sortable?: boolean;
}

export interface SortingObservable<T = any> {
  field?: keyof T;
  order?: 'ASC' | 'DESC';
}

type CellProps = {
  cell: GridColDef;
  disabled?: boolean;
};

// TODO this is unused for now but we'll need it in future. Commented out to meet code coverage
// const cellFilters: Record<string, any> = {
//   date: (value: Date) => (value ? new Date(value).toLocaleDateString() : 'n/a'),
//   datetime: (value: Date) => (value ? new Date(value).toLocaleString() : 'n/a'),
//   boolean: (value: boolean) => value.toString(),
// };

const getRowValue = (cell: any, row: any) => {
  const val = cell.field.split('.').reduce((prev: any, curr: any) => (prev ? prev[curr] : null), row || '');
  // val = cell.filter && cellFilters[cell.filter] ? cellFilters[cell.filter](val, row) : val;
  return val || val === 0 ? val : '';
};

const CellHeader = (props: CellProps) => {
  const { cell, disabled } = props;
  return cell.checked !== undefined && cell.setChecked !== undefined
    ? (<Checkbox
        id='grid-header-checkbox'
        ariaLabel='table-header-checkbox'
        cypressData='grid-header-checkbox'
        disabled={disabled}
        checked={cell.checked}
        onChange={() => cell.setChecked && cell.setChecked(!cell.checked)}
      >
        {cell.headerName}
      </Checkbox>
    ) : (
      <>{cell.headerName}</>
    );
};

export interface SubrowElementProps<T = any> {
  row: T;
  data?: any;
  actions?: any;
  index: number;
}

export interface SubrowProps<T = any> {
  Renderer: (props: SubrowElementProps<T>) => JSX.Element;
  data: any;
  actions: any;
}

interface GridProps {
  id: string;
  columns: GridColDef[];
  disabled?: boolean;
  loading?: boolean | ReactElement;
  zebraRows?: boolean;
  data: any[] | undefined;
  sorting?: SortingObservable;
  onSortingChanged?: () => void;
  onRowClick?: (index: number) => void;
  subrow?: SubrowProps;
  expandedRowIds?: any[];
  rowIdField?: string;
  rowLoadingField?: string;
  noRowsText?: string;
  className?: any;
}

const Grid = observer((props: GridProps) => {
  const { id, columns, loading, zebraRows, data, sorting, onSortingChanged, subrow, onRowClick, className, expandedRowIds, rowIdField, rowLoadingField, noRowsText, disabled } = props;
  let curId = 1;
  const ids = new WeakMap();

  const getKey = (object: any) => {
    if (ids.has(object)) {
      return ids.get(object);
    }
    const id = String(curId++);
    ids.set(object, id);
    return id;
  };

  const switchSorting = (field: string) => {
    if (!sorting) return;
    runInAction(() => {
      if (sorting.field === field) {
        sorting.order = sorting.order === 'ASC' ? 'DESC' : 'ASC';
      } else {
        sorting.field = field;
        sorting.order = 'ASC';
      }
    });
    if (onSortingChanged) {
      onSortingChanged();
    }
  };

  const handleRowClick = (index: number) => {
    if (onRowClick) onRowClick(index);
  };

  return (
    <table id={id} className={`spot-data-table spot-data-table--small-spacing spot-data-table--sortable ${className || ''}`}>
      <colgroup>
        {columns.map((cell, index) => (
          <col key={cell.key || cell.headerName} className={`spot-data-table__col spot-data-table__col--index-${index}`}/>
        ))}
      </colgroup>
      <thead>
        <tr>
          {columns.map((cell) => {
            const classes: string[] = [];
            if (cell.sortable) {
              if (sorting?.field === cell.field) {
                classes.push('spot-data-table__col--active');
                classes.push(sorting.order === 'ASC' ? 'spot-data-table__col--sort-ascending' : 'spot-data-table__col--sort-descending');
              } else {
                classes.push('spot-data-table__col--inactive');
              }
            } else {
              classes.push('spot-data-table__col--sort-disabled');
            }
            return (
              <th
                key={cell.key || cell.headerName}
                className={classes.join(' ')}
                onClick={() => cell.sortable && switchSorting(cell.field)}
                data-cy={`${id}-${cell.headerName}-header`}
              >
                <CellHeader
                  cell={cell}
                  disabled={disabled}
                />
              </th>
            );
          })}
        </tr>
      </thead>
      {loading ? (
        <tbody>
          <tr>
            <td colSpan={columns.length} style={loading === true ? { textAlign: 'center', padding: '10px' } : { padding: '10px' }}>
              {loading === true ? <Spinner /> : loading}
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {!data || data.length === 0 ? (
            <tr>
              <td colSpan={columns.length} className="no-data-row">{noRowsText || 'No data'}</td>
            </tr>
          ) : (
            <>
              {data.map((row, index) => (
                <React.Fragment key={getKey(row)}>
                  <tr
                    className={`${zebraRows ? (index % 2 === 1 ? 'gray-row' : '') : 'hover-gray'}`}
                    onClick={() => handleRowClick(index)}
                    onKeyDown={() => {}}
                    data-cy={`${id}-${index}-row`}
                  >
                    {rowLoadingField && row[rowLoadingField]
                      ? (
                        <td colSpan={columns.length} style={{ textAlign: 'center', padding: '6px' }}>
                          <Spinner size={24} />
                        </td>
                      ) : columns.map((cell) => (
                        <td key={cell.key || cell.headerName} data-cy={`${id}-${index}-${cell.headerName}-cell`}>
                          {cell.renderer ? cell.renderer(row, index, id, expandedRowIds) : getRowValue(cell, row)}
                        </td>
                      ))
                    }
                  </tr>
                  {subrow && expandedRowIds && rowIdField && expandedRowIds.includes(row[rowIdField]) && !(rowLoadingField && row[rowLoadingField]) && (
                    <tr className={`subrow ${!zebraRows ? 'hover-gray' : ''}`}>
                      <td colSpan={columns.length}>
                        {React.createElement(subrow.Renderer, { row, data: subrow.data, actions: subrow.actions, index })}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </tbody>
      )}
    </table>
  );
});

export default Grid;
