import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useAuth0 } from '@auth0/auth0-react';
import { MyIDEXXUserType } from '../../types/brm-types';
import { useStore } from '../../store/hooks';
import Spinner from '../Spinner/Spinner';
import { useSearchParams } from 'react-router-dom';
import { runInAction } from 'mobx';

interface AuthorizerProps {
  children: any;
}

const Authorizer = observer((props: AuthorizerProps) => {
  const { children } = props;
  const { getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect, error, user } = useAuth0<MyIDEXXUserType>();
  const { apiStore: store, globalStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const sapIdParam = searchParams.get('sapId');

  useEffect(() => {
    if (sapIdParam) {
      runInAction(() => {
        globalStore.selectedSap = {
          id: sapIdParam,
          name: '',
        };
      });
      searchParams.delete('sapId');
      setSearchParams(searchParams);
    } else if (!globalStore.sapId) {
      globalStore.toMyCsDashboard();
    }
  }, [sapIdParam, searchParams, setSearchParams, globalStore]);

  useEffect(() => {
    if (isAuthenticated) {
      store.authenticate(getAccessTokenSilently, user);
    }
  }, [getAccessTokenSilently, isAuthenticated, store, user]);

  useEffect(() => {
    if (store.sapId && !isLoading && !isAuthenticated) {
      void loginWithRedirect({
        login_hint: store.userEmail || undefined,
      });
    }
  }, [store.sapId, isAuthenticated, isLoading, loginWithRedirect, store.userEmail]);

  if (error) return (
    <div>
      <section id="error-page">
        <pre role="global-error">{JSON.stringify(error, null, 2)}</pre>
      </section>
    </div>
  );
  return store.isAuthenticated ? children : <Spinner text={store.loggingOut ? 'Signing you out' : 'One moment while we retrieve your practice information'}/>;
});

export default Authorizer;
