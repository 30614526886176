import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../store/hooks';
import Spinner from '../../components/Spinner/Spinner';

const DashboardStub = observer(() => {
  const { dashboardStubStore: store, apiStore } = useStore();

  useEffect(() => {
    if (apiStore.isAuthenticated) {
      if (store.sapId) {
        void store.init();
      }
    }
  }, [store, store.sapId, apiStore.isAuthenticated]);

  return <Spinner text="One moment while we retrieve your practice information" />;
});

export default DashboardStub;
