import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Button from '../../components/Button/Button';
import { useStore } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import './ConnectPractice.scss';
import Spinner from '../../components/Spinner/Spinner';
import Grid, { SubrowProps } from '../../components/Grid/Grid';
import { SubRowRenderer } from './renderers';
import { PracticeResponse } from '../../types/practices-types';

const ConnectPractice = observer(() => {
  const { connectPracticeStore: store, globalStore } = useStore();
  const navigate = useNavigate();
  const { loaders, colDefs, expandedRowIds } = store;

  useEffect(() => {
    store.init();
  }, [store]);

  const subrow: SubrowProps<PracticeResponse> = {
    Renderer: SubRowRenderer,
    data: {},
    actions: {},
  };

  return (
    <div className='connect-practice_wrapper'>
      <div className="page-heading">
        <div className='heading--level-1'>Connect your Practice with Trupanion</div>
        <div className='data-refresh-reminder'>
          This data gets refreshed every 12 hours.
        </div>
        <div className='spot-typography__text--tertiary'>
          <a href="https://vet.trupanion.com/automated-offers-info" target="_blank" rel="noopener noreferrer">Learn more</a> about how Trupanion can help increase
          your insured client base and connect with confidence.
          When you click Connect, you agree to the terms and conditions of this program found on the Learn more page.
        </div>
      </div>
        {globalStore.practices.length == 0 &&  
          (
            <Button
              className="retrieve-practices-btn"
              type="primary"
              size="small"
              disabled={loaders.retrieve}
              onClick={() => store.retrievePractices()}
              cypressData="retrieve-practices-btn"
            >
              Retrieve Practices
            </Button>
          )
        }
        {loaders.retrieve && <Spinner className="block-spinner" size={24} text="One moment while we retrieve your practices from your local Cornerstone Server." /> }
        {!loaders.retrieve && globalStore.practicesRetrieved && (
          <Grid
            id='practices-grid'
            columns={colDefs}
            data={globalStore.practices}
            expandedRowIds={expandedRowIds}
            rowIdField="id"
            subrow={subrow}
          />
        )}
        <div className='connect-practice_actions'>
          <Button type='link' size='small' className="back" onClick={() => navigate(-1)} cypressData="back-btn">Back</Button>
          <div className='main-buttons'>
            <Button type="secondary" size="small" cypressData="bashboard-btn" onClick={() => navigate('/')}>Dashboard</Button>
            <Button
              type="primary"
              size="small"
              disabled={!globalStore.practicesRetrieved || !globalStore.practicesConnected || loaders.connect}
              onClick={() => store.navigateToOffersPage()}
              cypressData="offers-setup-btn"
            >
              Offer Setup
            </Button>
          </div>
        </div>
    </div>
  );
});

export default ConnectPractice;
