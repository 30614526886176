import { v4 as uuidv4 } from 'uuid';
import { action, computed, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import { storageService } from '../services/storage.service';
import { MyIDEXXUserType } from '../types/brm-types';
import { backendUrl } from '../config/app';
import AbstractStore from './AbstractStore';
import { ResourcePermission, SapResourcePermission, UserApi, } from '../types/users-types';
import { OFFERS_PATH, PRACTICES_PATH, SPECIES_BREEDS_PATH, USERS_PATH } from './constants';
import { getPathWithQuery } from '../utils/url-utils';
import { SpeciesBreedsResponse } from '../types/species-breeds-types';
import { PracticeOffersResponse, PracticeResponse } from '../types/practices-types';

const USER_EMAIL = 'USER_EMAIL';
const LOGGING_OUT = 'LOGGING_OUT';

export default class ApiStore extends AbstractStore {
  @observable private _isAuthenticated = false;

  @observable private _loggingOut = false;

  @observable user: UserApi | undefined;

  @observable userEmail: string;

  private getAccessTokenSilently?: (...args: any) => Promise<string>;

  private readonly backEndUrl: string;

  private userGuid = uuidv4();

  public constructor(rootStore: RootStore) {
    super(rootStore);
    this.userEmail = storageService.getItem(USER_EMAIL, true);
    this._loggingOut = storageService.getItem(LOGGING_OUT, true) || false;
    this.backEndUrl = backendUrl();
  }

  @computed
  public get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  @action
  public authenticate(getAccessTokenSilently: (...args: any) => Promise<string>, user: MyIDEXXUserType | undefined): void {
    this.getAccessTokenSilently = getAccessTokenSilently;
    this._isAuthenticated = true;
    this.userEmail = user?.email || '';
    // setting userId for WalkMe
    (window as any).userId = user?.sub;
    storageService.replace(USER_EMAIL, this.userEmail, true);
  }

  @computed
  public get userPermissions(): ResourcePermission[] {
    return ([] as ResourcePermission[]).concat(
      ...(this.user?.globalResourcePermissions || []),
      ...(this.sapId ? this.user?.sapResourcePermissions?.find(srp => srp.sapId == this.sapId)?.resourcePermissions || [] : [])
    );
  }

  @computed
  public get userGlobalPermissions(): ResourcePermission[] {
    return this.user?.globalResourcePermissions || [];
  }

  @computed
  public get userSapPermissions(): SapResourcePermission[] {
    return this.user?.sapResourcePermissions || [];
  }

  @computed
  public get loggingOut(): boolean {
    return this._loggingOut;
  }

  public set loggingOut(value: boolean) {
    this._loggingOut = value;
    storageService.replace(LOGGING_OUT, value, true);
  }

  // *** users

  @action
  public async fetchCurrentUserDetails(): Promise<void> {
    this.userGuid = uuidv4();
    const users = await this.callBackendApi<UserApi[]>(USERS_PATH);
    if (users) {
      runInAction(() => this.user = users[0]);
    }
  }

  // *** species/breeds
  @action
  public async fetchSpeciesAndBreeds(sapId: string): Promise<SpeciesBreedsResponse> {
    return this.callBackendApi<SpeciesBreedsResponse>(getPathWithQuery(SPECIES_BREEDS_PATH, { sapId }));
  }

  // *** practices

  @action
  public async fetchPractices(sapId: string): Promise<PracticeResponse[]> {
    return this.callBackendApi<PracticeResponse[]>(getPathWithQuery(PRACTICES_PATH, { sapId }));
  }

  // *** offers

  @action
  public async fetchPracticesOffers(sapId: string): Promise<PracticeOffersResponse[]> {
    return this.callBackendApi<PracticeOffersResponse[]>(getPathWithQuery(OFFERS_PATH, { sapId }));
  }

  @action
  public async callBackendApi<T = any>(endpoint: string, options: RequestInit = {}): Promise<T> {
    const { headers, ...others } = options;
    if (!this.getAccessTokenSilently) {
      throw new Error(`Auth0 getAccessTokenSilently() method is not set`);
    }
    const accessToken = await this.getAccessTokenSilently();
    // const accessToken = '1234567890';
    const res = await fetch(`${this.backEndUrl}${endpoint}`, {
      ...others,
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
        'x-user-guid': this.userGuid,
      },
    });
    const data = await res.text();
    if (res.status !== 200) {
      throw new Error(`Http error ${res.status}: ${data}`);
    }
    return JSON.parse(data);
  }

  @action.bound
  public async backendPost<T = any>(endpoint: string, body: any): Promise<T> {
    return this.callBackendApi<T>(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  @action
  public signOut(): void {
    this.user = undefined;
    this._isAuthenticated = false;
    this.getAccessTokenSilently = undefined;
    this.loggingOut = true;
  }
}
