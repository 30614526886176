import { observer } from 'mobx-react';
import Button from '../../components/Button/Button';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ManageBreeds.scss';
import Grid, { SubrowProps } from '../../components/Grid/Grid';
import { useBlocker, useStore } from '../../store/hooks';
import { SubRowRenderer } from './renderers';
import type { Transition } from 'history';
import { runInAction } from 'mobx';
import SearchBar from '../../components/SearchBar/SearchBar';
import { PracticeSpeciesBreeds } from '../../types/species-breeds-types';

const ManageBreeds = observer(() => {
  const navigate = useNavigate();
  const { manageBreedsStore: store, globalStore} = useStore();
  const { colDefs , expandedRowIds, mappedSpeciesBreeds, loaders, hasUnsavedChanges, searchText} = store;

  useEffect(() => {
    store.init();
    return () => store.close();
  }, [store]);

  useBlocker(useCallback((tx: Transition): void => {
    if (hasUnsavedChanges) {
      globalStore.showLeavePagePopup(() => tx.retry());
    } else {
      tx.retry();
    }
  }, [globalStore, hasUnsavedChanges]));

  const subrow: SubrowProps<PracticeSpeciesBreeds> = {
    Renderer: SubRowRenderer,
    data: {},
    actions: {},
  };

  return (
    <div className='manage-breeds_wrapper'>
      <div className="page-heading">
        <div className='heading--level-1'>Manage Breeds</div>
        <div className='spot-typography__text--tertiary'>
          Match additional Cornerstone canine and feline breeds to Trupanion&apos;s breeds list.<br />
          This information helps to accurately assess offers eligibility of your clients.
        </div>
      </div>
      <SearchBar
        ariaLabel='breed-search'
        initialValue={searchText}
        placeholder="Search for Cornerstone Canine and Feline Breeds"
        onSearch={v => runInAction(() => store.searchText = v)}
        cypressData="search-agents"
      />
      <Grid
        id='breeds-management-grid'
        columns={colDefs}
        data={mappedSpeciesBreeds}
        expandedRowIds={expandedRowIds}
        rowIdField="id"
        subrow={subrow}
      />
      <div className='manage-breeds_actions'>
        <Button type='link' size='small' className="back" onClick={() => navigate(-1)} cypressData="back-btn">Back</Button>
        <div className='main-buttons'>
          <Button type="secondary" size="small" cypressData="dashboard-btn" onClick={() => navigate('/')}>Dashboard</Button>
          <Button
            type="primary"
            size="small"
            cypressData="update-cornerstone-btn"
            loading={loaders.save}
            onClick={() => store.saveBreedsMapping()}
          >
            Update Cornerstone
          </Button>
        </div>
      </div>
    </div>
  );
});

export default ManageBreeds;
