import { CustomElementProps } from '../../types/react-props-types';
import React from 'react';
import RadioButton from '../RadioButton/RadioButton';

const RADIO_GROUP_TYPE_CLASSES = {
  expanded: 'spot-form__radio-group--expanded',
  horizontal: 'spot-form__field-group--horizontal',
} as const;

interface RadioButtonGroupProps extends CustomElementProps {
  id: string;
  groupName: string;
  label?: string;
  type?: keyof typeof RADIO_GROUP_TYPE_CLASSES;
  small?: boolean;
  values: Readonly<any[]>;
  displayField?: string;
  onChange: (index: number) => void;
  checkedIndex: number;
  formatter?: (v: any) => string;
  disabled?: (index: number) => boolean;
  groupClass?: string;
  cypressData?: string;
}

const RadioButtonGroup = (props: RadioButtonGroupProps) => {
  const { id, groupName, label, type, small, values, displayField, onChange, formatter, className, checkedIndex, disabled, groupClass, cypressData } = props;
  const formatValue = (value) => (formatter ? formatter(value) : (displayField ? value[displayField] : value));

  return (
    <fieldset
      className={`spot-form__radio-group ${type ? RADIO_GROUP_TYPE_CLASSES[type] : ''} ${className || ''}`}
      role="radiogroup"
      data-cy={cypressData}
    >
      {label && <legend className="spot-form__radio-group-label">{label}</legend>}
      <div className="spot-form__radio-group-inner">
        {values.map((value, index) => (
          <RadioButton
            id={`${id}__${index}`}
            groupName={groupName}
            className={groupClass}
            ariaLabel={formatValue(value)}
            key={displayField ? value[displayField] : value}
            checked={checkedIndex === index}
            disabled={(disabled && disabled(index)) || false}
            onChange={checked => checked && onChange(index)}
            small={small}
            cypressData={`${cypressData}-${formatValue(value)}-rb`}
          >
            {formatValue(value)}
          </RadioButton>
        ))}
      </div>
    </fieldset>
  );
};

export default RadioButtonGroup;
