import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Transition } from 'history';
import Button from '../../components/Button/Button';
import './ManageSpecies.scss';
import { useBlocker, useStore } from '../../store/hooks';
import Spinner from '../../components/Spinner/Spinner';
import Grid from '../../components/Grid/Grid';
import { isDefined } from '../../utils/object-utils';
import { runInAction } from 'mobx';

const ManageSpecies = observer(() => {
  const { manageSpeciesStore: store, globalStore } = useStore();
  const navigate = useNavigate();
  const { loaders, colDefs, hasUnsavedChanges, singleItemEditMode, currentItemIndex } = store;

  useEffect(() => {
    void store.init();
  }, [store]);

  useBlocker(useCallback((tx: Transition): void => {
    if (hasUnsavedChanges || isDefined(currentItemIndex)) {
      globalStore.showLeavePagePopup(() => tx.retry());
    } else {
      runInAction(() => store.hasUnsavedChanges = false);
      tx.retry();
    }
  }, [currentItemIndex, globalStore, hasUnsavedChanges, store]));


  return (
    <div className='manage-species_wrapper'>
      <div className="page-heading">
        <div className='heading--level-1'>Manage Species</div>
        <div className='spot-typography__text--tertiary'>
          Match your Cornerstone canine and feline species to Trupanion&apos;s species list.<br />
          This information is required to accurately assess eligibility for offers to your clients.
        </div>
      </div>
      {loaders.page
        ? <Spinner />
        : <>
          {globalStore.practiceSpeciesBreeds.length === 0
            ? (
              <Button
                className="retrieve-species-and-breeds-btn"
                type="primary"
                size="small"
                disabled={loaders.retrieve}
                onClick={() => store.retrieveSpeciesAndBreeds()}
                cypressData="retrieve-from-cornerstone-btn"
              >
                Retrieve From Cornerstone
              </Button>
            ) : (
              <Button
                className="retrieve-species-and-breeds-btn"
                icon="refresh-redo"
                type="link"
                size="small"
                disabled={loaders.retrieve}
                onClick={() => store.retrieveSpeciesAndBreeds()}
                cypressData="refresh-from-cornerstone-btn"
              >
                Refresh From Cornerstone
              </Button>
            )
          }
          {loaders.retrieve && <Spinner className="block-spinner" size={24} text="One moment while we retrieve your species and breeds information from your local Cornerstone Server." /> }
          {!loaders.retrieve && globalStore.practiceSpeciesBreeds.length > 0 && (
            <Grid
              id='species-management-grid'
              columns={colDefs}
              data={globalStore.practiceSpeciesBreeds}
            />
          )}
          <div className='manage-species_actions'>
            <Button type='link' size='small' className="back" onClick={() => navigate(-1)} cypressData="back-btn">Back</Button>
            <div className='main-buttons'>
              <Button
                type="secondary"
                size="small"
                onClick={() => navigate('/')}
                disabled={singleItemEditMode && isDefined(currentItemIndex)}
                cypressData="dashboard-btn"
              >Dashboard
              </Button>
              <Button
                type="primary"
                size="small"
                disabled={(singleItemEditMode ? isDefined(currentItemIndex) : !hasUnsavedChanges) || loaders.save}
                loading={loaders.save}
                onClick={() => store.saveAndRedirectToManageBreeds()}
                cypressData="manage-breeds-btn"
              >
                Manage Breeds
              </Button>
            </div>
          </div>
        </>
      }
    </div>
  );
});

export default ManageSpecies;
