import AbstractStore from '../../store/AbstractStore';
import { action, computed, observable, runInAction } from 'mobx';
import { addressRenderer, nameRenderer, subrowActionsRenderer, subrowPracticeNameRenderer, trupanionHospitalRenderer } from './renderers';
import type { PracticeHospitalResponse, PracticeResponse } from '../../types/practices-types';
import { isDefined, sortByField } from '../../utils/object-utils';
import { GridColDef } from '../../components/Grid/Grid';
import { PRACTICES_PATH } from '../../store/constants';

interface Loaders {
  disconnect?: boolean;
  retrieve?: boolean;
  connect?: boolean;
  getHospitalByIdIndex?: number;
  searchHospitalIndex?: number;
}

export default class ConnectPracticeStore extends AbstractStore {
  @observable loaders: Loaders = {};

  @observable lookupPracticeIndex: number | undefined = undefined;

  @observable subrowPracticeIndex: number | undefined = undefined;

  @observable hospitalOptions: PracticeHospitalResponse[] = [];

  @observable hospitalId = '';

  @observable addManuallyEnabled: Record<string, boolean> = {};

  @computed
  public get colDefs(): GridColDef<PracticeResponse>[] {
    return [
      { headerName: 'Practice Name', field: '', key: 'practiceName', renderer: nameRenderer },
      { headerName: 'Address (Street, City, State/Prov, Zip/Postal)', field: '', renderer: addressRenderer },
      { headerName: 'Phone', field: 'phone' },
      { headerName: 'Trupanion Unique ID', field: '', key: 'hospitalId', renderer: trupanionHospitalRenderer },
    ];
  }

  @computed
  public get expandedRowIds(): string[] {
    return isDefined(this.lookupPracticeIndex) ? [this.root.globalStore.practices[this.lookupPracticeIndex].id] : [];
  }

  public subRowColDefs(rowIndex: number): GridColDef<PracticeHospitalResponse>[] {
    return [
      { headerName: 'Practice Name', field: '', key: 'practiceName', renderer: subrowPracticeNameRenderer },
      { headerName: 'Address (Street, City, State/Prov, Zip/Postal)', field: 'address' },
      { headerName: 'Phone', field: 'phone' },
      { headerName: 'Actions', field: '', key: 'hospitalId', renderer: subrowActionsRenderer(rowIndex) },
    ];
  }

  @action
  public init(): void {
    this.lookupPracticeIndex = undefined;
    this.addManuallyEnabled = {};
  }

  @action
  public async retrievePractices(): Promise<void> {
    runInAction(() => this.loaders.retrieve = true);
    try {
      this.root.globalStore.setPractices(await this.root.apiStore.fetchPractices(this.sapId));
    } catch (e: any) {
      this.showGenericError();
    }
    runInAction(() => this.loaders.retrieve = false);
  }

  @action
  public navigateToOffersPage(): void {
    this.root.globalStore.stepsCompleted.practices = true;
    this.navigate('/offer-setup');
  }

  @action
  public async updatePracticesProperties(index: number, hospitalId?: string): Promise<void> {
    try {
      runInAction(() => {
        if (isDefined(hospitalId)) this.loaders.connect = true;
        else this.loaders.disconnect = true;
      });
      await this.root.apiStore.backendPost(PRACTICES_PATH, {
        action: 'SAVE_PRACTICE_PROPERTIES',
        sapId: this.sapId,
        practiceProperties: [{
          id: this.root.globalStore.practices[index].id,
          hospitalId,
        }],
      });
      runInAction(() => this.root.globalStore.practices[index].hospitalId = hospitalId);
    } catch (e: any) {
      if (e.message?.includes('Trupanion activation error')) {
        this.root.globalStore.showOkPopup('Unable To Connect Practice', 'We were unable to connect your Cornerstone practice with Trupanion.');
      } else if (e.message?.includes('Trupanion deactivation error')) {
        this.root.globalStore.showOkPopup('Unable To Disconnect Practice', 'We were unable to disconnect your Cornerstone practice from Trupanion.');
      } else {
        this.showGenericError();
      }
    } finally {
      runInAction(() => {
        if (isDefined(hospitalId)) this.loaders.connect = false;
        else this.loaders.disconnect = false;
      });
    }
  }

  @action
  public async searchForTrupanionHospital(practice: PracticeResponse, index: number | undefined): Promise<void> {
    try {
      runInAction(() => this.loaders.searchHospitalIndex = index);
      const hospitalOptions = await this.root.apiStore.backendPost<PracticeHospitalResponse[]>(PRACTICES_PATH, {
        action: 'SEARCH_FOR_TRUPANION_HOSPITALS',
        sapId: this.sapId,
        practiceId: practice.id,
      });
      runInAction(() => {
        this.hospitalOptions = sortByField(hospitalOptions, 'name');
        this.lookupPracticeIndex = index;
        this.subrowPracticeIndex = 0;
      });
    } catch {
      this.showGenericError();
    } finally {
      runInAction(() => this.loaders.searchHospitalIndex = undefined);
    }
  }

  @action
  public async getTrupanionHospitalById(index: number): Promise<void> {
    try {
      runInAction(() => this.loaders.getHospitalByIdIndex = index);
      const hospitalOptions = await this.root.apiStore.backendPost<PracticeHospitalResponse[]>(PRACTICES_PATH, {
        action: 'SEARCH_BY_TRUPANION_HOSPITAL_ID',
        sapId: this.sapId,
        hospitalId: this.hospitalId,
      });
        runInAction(() => {
        this.hospitalOptions = sortByField(hospitalOptions, 'name');
        this.lookupPracticeIndex = index;
        this.subrowPracticeIndex = 0;
      });
    } catch {
      this.showGenericError();
    } finally {
      runInAction(() => this.loaders.getHospitalByIdIndex = undefined);
    }
  }

  @action
  public async connectHospitalId(index: number, hospitalId: string): Promise<void> {
    await this.updatePracticesProperties(index, hospitalId);
    runInAction(() => this.lookupPracticeIndex = undefined);
  }

  @action
  public cancelLookup(index: number): void {
    const practiceId = this.root.globalStore.practices[index].id;
    this.addManuallyEnabled[practiceId] = true;
    this.lookupPracticeIndex = undefined;
  }

  private showGenericError(): void {
    this.root.globalStore.showOkPopup('Connection Error', 'An unexpected error occurred, please try again.');
  }
}
