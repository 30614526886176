import AbstractStore from '../../store/AbstractStore';
import { action, computed, observable, runInAction } from 'mobx';
import { GridColDef } from '../../components/Grid/Grid';
import { automatedPopupsRenderer, manualButtonRenderer } from './renderers';
import { ManualButtonsValues, PracticeOffersResponse, PracticeResponse } from '../../types/practices-types';
import { OFFERS_PATH } from '../../store/constants';
import { isDefined } from '../../utils/object-utils';

interface Loaders {
  page?: boolean;
  retrieve?: boolean;
  save?: boolean;
}

const getFullName = (practice: PracticeResponse): string => [practice.name1, practice.name2, practice.isPrimary ? '(Primary)' : ''].filter(p => p).join(' ');
export default class OfferSetupStore extends AbstractStore {
  @observable loaders: Loaders = {};
  @computed
  public get colDefs(): GridColDef<PracticeOffersResponse>[] {
    return [
      { headerName: 'Practice Name', field: 'practiceName' },
      { headerName: 'Automated Pop-ups', field: '', renderer: automatedPopupsRenderer },
      { headerName: 'Manual Buttons', field: '', renderer: manualButtonRenderer },
    ];
  }

  @computed
  public get filteredPracticeOffers(): PracticeOffersResponse[] {
    return this.root.globalStore.practiceOffers.filter(po => {
      const practice = this.root.globalStore.practices.find(p => p.id === po.practiceId);
      return isDefined(practice?.hospitalId);
    });
  }

  @action
  public async init(): Promise<void> {
    if (this.root.globalStore.practiceOffers.length > 0) return;
    runInAction(() => {
      this.loaders = { page: true };
      this.hasUnsavedChanges = false;
    });
    const practiceOffers = await this.root.apiStore.fetchPracticesOffers(this.sapId);
    await this.combineOffers(practiceOffers);
    runInAction(() => this.loaders.page = false);
  }

  @action
  public async combineOffers(practiceOffers?: PracticeOffersResponse[]): Promise<void> {
    const offers = practiceOffers || this.root.globalStore.practiceOffers;
    this.root.globalStore.practiceOffers = this.root.globalStore.practices.map(p => {
      const offer = offers.find(po => po.practiceId === p.id);
      if (offer) return {
        ...offer,
        practiceName: getFullName(p),
      };
      return {
        practiceId: p.id,
        practiceName: getFullName(p),
        automatedPopups: 'Both',
        manualButtons: [...ManualButtonsValues],
      };
    });
  }

  @action
  public async retrievePractices(): Promise<void> {
    runInAction(() => this.loaders.retrieve = true);
    await this.root.connectPracticeStore.retrievePractices();
    await this.combineOffers();
    runInAction(() => this.loaders.retrieve = false);
  }

  @action
  public async saveOffersAndNavigateToSpeciesBreeds(): Promise<void> {
    try {
      runInAction(() => this.loaders.save = true);
      await this.root.apiStore.backendPost(OFFERS_PATH, {
        sapId: this.sapId,
        practiceOffers: this.filteredPracticeOffers,
      });
      runInAction(() => {
        this.root.globalStore.stepsCompleted.offers = true;
        this.hasUnsavedChanges = false;
      });
      setTimeout(() => this.navigate('/species'), 10);
    } catch (e: any) {
      this.showGenericError();
    } finally {
      runInAction(() => this.loaders.save = false);
    }

  }

  private showGenericError(): void {
    this.root.globalStore.showOkPopup('Connection Error', 'An unexpected error occurred, please try again.');
  }
}
