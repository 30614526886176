import { ReactElement } from 'react';
import BaseLayout from './layouts/BaseLayout/BaseLayout';
import GlobalStore from './store/GlobalStore';
import { ResourcePermission, } from './types/users-types';
import DashboardStub from './pages/DashboardStub/DashboardStub';
import ConnectPractice from './pages/ConnectPractice/ConnectPractice';
import OfferSetup from './pages/OfferSetup/OfferSetup';
import ManageSpecies from './pages/ManageSpecies/ManageSpecies';
import ManageBreeds from './pages/ManageBreeds/ManageBreeds';

type MENU_CATEGORY = 'MAIN' | 'PRACTICE' | 'PRACTICE_MANAGEMENT';

export interface PathType {
  Component?: any;
  Layout: any;
  category: MENU_CATEGORY;
  showInCategories: MENU_CATEGORY[];
  name?: string;
  item?: ReactElement;
  isSection?: boolean;
  enabled?: (store: GlobalStore) => boolean;
  display?: (store: GlobalStore) => boolean;
  route?: string;
  globalResourcePermissions?: PathResourcePermission[];
  sapResourcePermissions?: PathResourcePermission[];
}

export interface PathResourcePermission {
  resourceId: string;
  permissions: string[];
}

const paths: PathType[] = [
  {
    route: '/',
    name: 'Dashboard',
    Component: DashboardStub,
    category: 'MAIN',
    showInCategories: ['MAIN', 'PRACTICE'],
    Layout: BaseLayout,
  },
  {
    name: 'Trupanion Integration',
    category: 'MAIN',
    showInCategories: ['MAIN'],
    isSection: true,
    Layout: BaseLayout,
  },
  {
    route: '/connect-practice',
    name: 'Connect Practice',
    Component: ConnectPractice,
    Layout: BaseLayout,
    category: 'MAIN',
    showInCategories: ['MAIN'],
  },
  {
    route: '/offer-setup',
    name: 'Offer Setup',
    Component: OfferSetup,
    Layout: BaseLayout,
    display: store => !!store.stepsCompleted.practices,
    category: 'MAIN',
    showInCategories: ['MAIN'],
  },
  {
    route: '/species',
    name: 'Manage Species',
    Component: ManageSpecies,
    Layout: BaseLayout,
    display: store => !!store.stepsCompleted.offers,
    category: 'MAIN',
    showInCategories: ['MAIN'],
  },
  {
    route: '/breeds',
    name: 'Manage Breeds',
    Component: ManageBreeds,
    Layout: BaseLayout,
    display: store => !!store.stepsCompleted.species,
    category: 'MAIN',
    showInCategories: ['MAIN'],
  },
];

export const isSapPrivate = (path: PathType | undefined): boolean => path && ['PRACTICE', 'PRACTICE_MANAGEMENT'].includes(path.category) || false;

export const getPathsForCategory = (category: MENU_CATEGORY): PathType[] => {
  return paths.filter(path => path.showInCategories.includes(category));
};

export const getPathPermissions = (path: PathType, currentPath: PathType | undefined): ResourcePermission[] => {
  if (!currentPath) return [];
  return (isSapPrivate(currentPath) ? path.sapResourcePermissions || [] : path.globalResourcePermissions || []).map(pathPerm => ({
    resourceId: pathPerm.resourceId,
    permissions: pathPerm.permissions.map(p => ({ id: p })),
  }));
};

export default paths;
