import { makeObservable } from 'mobx';
import ApiStore from './ApiStore';
import GlobalStore from './GlobalStore';
import AbstractStore from './AbstractStore';
import ConnectPracticeStore from '../pages/ConnectPractice/ConnectPracticeStore';
import DashboardStubStore from '../pages/DashboardStub/DashboardStubStore';
import SideMenuStore from '../components/SideMenu/SideMenuStore';
import OfferSetupStore from '../pages/OfferSetup/OfferSetupStore';
import ManageSpeciesStore from '../pages/ManageSpecies/ManageSpeciesStore';
import ManageBreedsStore from '../pages/ManageBreeds/ManageBreedsStore';

export default class RootStore {
  public readonly globalStore = new GlobalStore(this);
  public readonly apiStore = new ApiStore(this);
  public readonly sideMenuStore = new SideMenuStore(this);
  public readonly dashboardStubStore = new DashboardStubStore(this);
  public readonly connectPracticeStore = new ConnectPracticeStore(this);
  public readonly offerSetupStore = new OfferSetupStore(this);
  public readonly manageSpeciesStore = new ManageSpeciesStore(this);
  public readonly manageBreedsStore = new ManageBreedsStore(this);

  constructor() {
    Object.keys(this).forEach(storeName => {
      const store = this[storeName];
      if (!(store instanceof AbstractStore)) throw new Error(`${storeName} is not an instance of AbstractStore subclass`);
      makeObservable(store);
    });
  }

  // no methods allowed in this class because they will be anyway unavailable in useStore() hook result
}
