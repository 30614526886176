import { BaseRendererProps } from '../../types/react-props-types';
import { PracticeSpeciesBreeds } from '../../types/species-breeds-types';
import { observer } from 'mobx-react';
import DropDown from '../../components/DropDown/DropDown';
import { runInAction } from 'mobx';
import React from 'react';
import { useStore } from '../../store/hooks';
import SpotIconButton from '../../components/SpotIconButton/SpotIconButton';
import { isDefined, sortByField } from '../../utils/object-utils';

const VendorSpeciesRenderer = observer((props: BaseRendererProps<PracticeSpeciesBreeds>) => {
  const { row, index } = props;
  const { manageSpeciesStore: store, globalStore } = useStore();

  const values = [
    { id: undefined, name: 'Select Species' },
    ...sortByField(Object.values(globalStore.vendorSpeciesBreeds), 'name').map(s => ({
      id: s.id,
      name: s.name,
    }))
  ];
  const changeSpecies = (vendorIndex: number) => {
    runInAction(() => {
      if (isDefined(store.currentItemIndex)) {
        store.currentValue = values[vendorIndex].id;
      } else {
        row.vendorItemId = values[vendorIndex].id;
        store.hasUnsavedChanges = true;
      }
    });
  };

  return (
    <DropDown
      values={values}
      displayField="name"
      onChange={idx => changeSpecies(idx)}
      selectedIndex={values.findIndex(vs => vs.id === (store.currentItemIndex === index ? store.currentValue : row.vendorItemId))}
      disabled={store.singleItemEditMode ? store.currentItemIndex !== index : row.editDisabled}
    />
  );
});

const ActionsRenderer = observer((props: BaseRendererProps<PracticeSpeciesBreeds> & { index: number }) => {
  const { row, index } = props;
  const { manageSpeciesStore: store } = useStore();

  if (store.singleItemEditMode && store.currentItemIndex === index) return <>
    <SpotIconButton
      path="save"
      onClick={() => runInAction(() => store.saveItem(index))}
      title="Save"
      loading={store.loaders.saveSingle}
      cypressData={`save-${row.id}-btn`}
    />
    <SpotIconButton
      path="cancel"
      onClick={() => runInAction(() => store.currentItemIndex = undefined)}
      title="Cancel"
      disabled={store.loaders.saveSingle}
      cypressData={`cancel-${row.id}-btn`}
    />
  </>;

  return (
    <SpotIconButton
      path="edit"
      disabled={store.singleItemEditMode ? isDefined(store.currentItemIndex) : !row.editDisabled}
      onClick={() => runInAction(() => {
        if (store.singleItemEditMode) {
          store.currentItemIndex = index;
          store.currentValue = row.vendorItemId;
        } else {
          row.editDisabled = false;
        }
      })}
      title="Edit species"
      cypressData={`edit-${row.id}-btn`}
    />
  );
});

export const vendorSpeciesRenderer = (row: PracticeSpeciesBreeds, index: number) => <VendorSpeciesRenderer row={row} index={index}/>;

export const actionsRenderer = (row: PracticeSpeciesBreeds, index: number) => <ActionsRenderer row={row} index={index}/>;

export const MessageMapBreeds = () => <>
  Click <strong>Manage Breeds</strong> to complete setup and send<br />
  the information to your Cornerstone server.
</>;
