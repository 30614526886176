import React from 'react';
import './Spinner.scss';
import type { Property } from 'csstype';

interface SpinnerProps {
  text?: string;
  size?: number;
  color?: Property.Color;
  className?: string;
  noHint?: boolean;
}

const Spinner = (props: SpinnerProps) => {
  const { text, size, color, className, noHint } = props;
  return (
    <div className={`spinner-loader ${className || ''}`}>
      <svg
        role="spinner"
        className="spinner"
        style={{ fill: color }}
        width={size || 33}
        height={size || 33}
        viewBox="0 0 33 33"
        xmlns="http://www.w3.org/2000/svg"
      >
        {!noHint && <title>spinner</title>}
        <path d="M26.233 3.605l2.828 2.829-5.657 5.657-2.828-2.829z"/>
        <path opacity=".4" d="M32.333 14.333v4h-8v-4zm-8.929 6.243l5.657 5.657-2.828 2.828-5.657-5.657zm-5.07 11.758h-4v-8h4zM9.262 20.576l2.829 2.828-5.657 5.657-2.829-2.828zM.333 18.333v-4h8v4z"/>
        <path opacity=".6" d="M6.434 3.605l5.657 5.657-2.829 2.829-5.657-5.657z"/>
        <path opacity=".8" d="M14.334.334h4v8h-4z"/>
      </svg>
      {text && <span>{text}</span>}
    </div>
  );
};

export default Spinner;
